var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-wrap justify-between rounded-md border p-3 m-3 mt-6 bg-white",
    attrs: {
      "id": "actionBar"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, _vm._l(_vm.actions.filter(function (a) {
    return a.position === 'start';
  }), function (item, index) {
    return _c('div', {
      key: index
    }, [item.type == 'button' ? _c('button', {
      staticClass: "flex items-center",
      class: item.style
    }, [item.icon ? _c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]) : _vm._e(), item.type == 'link' ? _c('router-link', {
      staticClass: "flex items-center",
      class: item.style,
      attrs: {
        "to": item.to
      }
    }, [item.icon ? _c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]) : _vm._e()], 1);
  }), 0), _c('div', {
    staticClass: "flex"
  }), _c('div', {
    staticClass: "flex"
  }, _vm._l(_vm.actions.filter(function (a) {
    return a.position === 'end';
  }), function (item, index) {
    return _c('div', {
      key: index
    }, [item.type == 'button' ? _c('button', {
      staticClass: "flex items-center",
      class: item.style
    }, [item.icon ? _c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]) : _vm._e(), item.type == 'link' ? _c('router-link', {
      staticClass: "flex items-center",
      class: item.style,
      attrs: {
        "to": item.to
      }
    }, [item.icon ? _c('i', {
      staticClass: "material-icons mr-2"
    }, [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]) : _vm._e()], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }